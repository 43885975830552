var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('h1',{staticClass:"headline"},[_vm._v("商品买家秀")]),_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入商品id"},on:{"sendSearchName":_vm.getSearchName}}),_c('Introduce',{attrs:{"introduce":_vm.introduce}}),_vm._m(0),_c('div',{staticClass:"bannerContent",staticStyle:{"padding-bottom":"20px"}},[_c('img',{staticStyle:{"dispaly":"block","width":"100px","height":"100px"},attrs:{"src":_vm.goodsEntity[0].picUrl,"alt":"图片加载中"}}),_c('div',{staticStyle:{"margin-left":"16px"}},[_c('p',{staticStyle:{"font-size":"16px","color":"#333333","letter-spacing":"0","margin":"6px 0"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].goodsName)+" ")]),_c('p',{staticStyle:{"font-size":"14px","color":"#666666","letter-spacing":"0","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].nick)+" ")]),_c('p',{staticStyle:{"font-size":"16px","color":"#333333","letter-spacing":"0"}},[_vm._v(" "+_vm._s(_vm.goodsEntity[0].price)+" ")])])]),_c('div',[_c('el-table',{staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.mjxList,"header-cell-style":{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          'font-size': '14px',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }}},[_c('div',{staticClass:"table-in"},[_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"评价内容"}}),_c('el-table-column',{attrs:{"align":"center","prop":"goodsId","label":"宝贝id","width":"120px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"nick","label":"买家昵称","width":"80px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"skuMap","label":"SKU","width":"180px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"date","label":"日期","width":"100px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"picture","width":"300px","label":"买家秀"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.pics),function(item,index){return _c('el-popover',{key:index,attrs:{"placement":"bottom","trigger":"click"}},[_c('a',{attrs:{"href":item,"target":"_blank","title":"查看最大化图片"}},[_c('img',{staticStyle:{"width":"160px","height":"160px"},attrs:{"src":item}})]),_c('img',{staticStyle:{"width":"50px","height":"50px","cursor":"pointer"},attrs:{"slot":"reference","src":item},slot:"reference"})])})}}])})],1)]),_c('div',{staticClass:"block",staticStyle:{"height":"40px","padding-bottom":"10px"}},[_c('el-pagination',{staticStyle:{"margin-top":"20px","float":"right"},attrs:{"current-page":_vm.currentPage,"page-size":20,"layout":"total, prev, pager, next,jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"font-size":"24px","color":"#111111","letter-spacing":"0","margin-bottom":"20px"}},[_c('b',[_vm._v("商品信息")])])}]

export { render, staticRenderFns }