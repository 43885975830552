<template>
  <div>
    <div class="banner">
      <h1 class="headline">商品买家秀</h1>
      <!-- 使用搜索框组件 -->
      <InputView
        :extractProductId="true"
        :CanYouClick="CanYouClick"
        placeholder-name="请输入商品id"
        @sendSearchName="getSearchName"
      ></InputView>
      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
      <!-- 相关商品信息 -->
      <div
        style="
          font-size: 24px;
          color: #111111;
          letter-spacing: 0;
          margin-bottom: 20px;
        "
      >
        <b>商品信息</b>
      </div>
      <div class="bannerContent" style="padding-bottom: 20px">
        <img
          :src="goodsEntity[0].picUrl"
          style="dispaly: block; width: 100px; height: 100px"
          alt="图片加载中"
        />
        <div style="margin-left: 16px">
          <p
            style="
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              margin: 6px 0;
            "
          >
            {{ goodsEntity[0].goodsName }}
          </p>
          <p
            style="
              font-size: 14px;
              color: #666666;
              letter-spacing: 0;
              margin-bottom: 16px;
            "
          >
            {{ goodsEntity[0].nick }}
          </p>
          <p style="font-size: 16px; color: #333333; letter-spacing: 0">
            {{ goodsEntity[0].price }}
          </p>
        </div>
      </div>
      <!-- 以下部位为表格 -->
      <div>
        <el-table
          :data="mjxList"
          style="width: 100%; border: 1px solid #f4f4f4"
          :header-cell-style="{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            'font-size': '14px',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }"
        >
          <div class="table-in">
            <el-table-column align="center" prop="title" label="评价内容">
            </el-table-column>
            <el-table-column
              align="center"
              prop="goodsId"
              label="宝贝id"
              width="120px"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="nick"
              label="买家昵称"
              width="80px"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="skuMap"
              label="SKU"
              width="180px"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="date"
              label="日期"
              width="100px"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="picture"
              width="300px"
              label="买家秀"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="bottom"
                  trigger="click"
                  v-for="(item, index) in scope.row.pics"
                  :key="index"
                >
                  <!--trigger属性值：hover、click、focus 和 manual-->
                  <a :href="item" target="_blank" title="查看最大化图片">
                    <img :src="item" style="width: 160px; height: 160px" />
                  </a>
                  <img
                    slot="reference"
                    :src="item"
                    style="width: 50px; height: 50px; cursor: pointer"
                  />
                </el-popover>
              </template>
            </el-table-column>
          </div>
        </el-table>
        <!-- 以下部分为分页器 -->
        <div class="block" style="height:40px;padding-bottom:10px">
          <el-pagination
            style="margin-top: 20px; float: right"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            layout="total, prev, pager, next,jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaiJiaXiu } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      loading: false, //是否加载中
      CanYouClick: true, //搜索框是否允许点击
      goodsEntity: [
        {
          id: null,
          goodsId: 599847242748,
          nick: "大为旗舰店",
          goodsName:
            "手套劳保耐磨胶皮防滑丁腈橡胶乳胶工地干活工业加厚工作防水透气",
          picUrl:
            "//img.alicdn.com/imgextra/i1/2200806458312/O1CN01Rrbzxi2BGwikgv83r_!!2200806458312.jpg",
          price: "39.60",
          propsName: null,
          otherParams: null,
          createTime: null,
          cid: null,
        },
      ],
      mjxList: [
        {
          goodsId: "599847242748",
          nick: "2**行",
          skuMap: "颜色分类：308经济款   6双试用,尺码：L",
          date: "2021-02-04",
          title:
            "到货很快，这样干活时，就不怕扎手外观干净，没有胶气，没有疙瘩，带上也挺合适，戴在手上非常舒适，了，非常好的，一点儿也不硬戴上干活利落，手感很好，不显着臃肿比超市的质量好还便宜",
          pics: [
            "//img.alicdn.com/imgextra/i1/0/O1CN01K7Mxtj1X3aZdoiAXM_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01DluH611X3aZfhQWZ4_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01cCTvHc1X3aZidKyoI_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01O7AkOc1X3aZitBdAD_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01R7xDTW1X3aZl7rsdg_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "半**8",
          skuMap: "颜色分类：右 手  308经济款24只,尺码：L",
          date: "2021-01-03",
          title:
            "这个手套质量还可以，我们搞机械的天天要戴，比较耐磨，滑滑软软的，带上效果也不错，做事带刚刚好，我做事喜欢带手套。这个手套戴在手上不滑。干活利索不受影响。 ",
          pics: [
            "//img.alicdn.com/imgextra/i3/0/O1CN01WUfwHw1WDpoMRYEZa_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01hcDOvo1WDpoODL3uN_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01s0DCS21WDpoPZQrde_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01vPBpdJ1WDpoQe1pbv_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01mv0WqI1WDpoUfAbY7_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "露**格",
          skuMap: "颜色分类：促销 518耐磨款 12双+送12双花纱,尺码：L",
          date: "2021-01-17",
          title:
            "手套到的挺快的！！质量不错！耐磨防滑好用！！价格实惠，下次还会继续光顾的 ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01MwrlAt1gartGzzn9V_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01jCn8bX1gartPXfnKe_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01ooqBr41gartSYzWrl_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01H1N9mQ1gartSxGcMt_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01hrSnkX1gartUYTPTA_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "杜**7",
          skuMap: "颜色分类：308经济款  12双（无赠品）,尺码：L",
          date: "2021-01-27",
          title:
            "手套到了就开始用了，这个手套干活戴起来很好用，戴上活利落，手感很好，不显着臃肿比超市的质量好还便宜，戴着很舒适。而且价格实惠✨可谓物美价廉 ",
          pics: [
            "//img.alicdn.com/imgextra/i3/0/O1CN01YCxTVR29k8lkn0YC1_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN015qtBuF29k8lmfiId3_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01zTNfgd29k8lpqCFpu_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01n2E6fe29k8lt46LsD_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01raxBGn29k8lt46cVy_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "h**贝",
          skuMap: "颜色分类：308经济款  24双（无赠品）,尺码：L",
          date: "2020-12-16",
          title:
            "手套质量很不错，满满的一大包，产品功能：很耐磨，很耐磨 外观材质：材质很厚实 商品品质：性价比挺高的，手感很好，试戴了下很合适也比较柔软，比实体店划算多了，快递服务很好，很快就拿到货了，包装完好。非常推荐，  ",
          pics: [
            "//img.alicdn.com/imgextra/i3/0/O1CN01c3cjK61x9hrOZLa18_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01Lrk7NV1x9hrOZNvZv_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01SYEAzd1x9hrSGMmRq_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01azxAKD1x9hrSGNFYp_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01erLE2q1x9hrSTN0L8_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "香**9",
          skuMap: "颜色分类：308经济款  12双（无赠品）,尺码：L",
          date: "2021-01-05",
          title:
            "手套大小适合、胶漆材料不错，没有味道、质量看上去蛮好的比实体店便宜，手套的舒适度很好，大小标准，弹力够好，质量也是杠杠的，已经购买多次了，值得信赖的又一次购物之旅， ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01ZNB0Oa1t6GQTB1fme_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01hJo7rb1t6GQdtHxpY_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01XNRmeV1t6GQdtKB2Q_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN014pZh2x1t6GQdtKZz2_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01MsCvuZ1t6GQgc6Qkf_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "我**儿",
          skuMap: "颜色分类：308经济款  72双（无赠品）,尺码：L",
          date: "2021-01-17",
          title:
            "产品功能：很实用，而且性价比很高，比其他家实惠，用起来也可以，大小合适，不会太小，总体满意，包装也不错，外观材质：看起来比超市的好 商品品质：没想到比平时在店里买的质量好，关键还更划算，手套很不错，挺好的戴上干活也挺顺手！  ",
          pics: [
            "//img.alicdn.com/imgextra/i3/0/O1CN01t9B7Al1GlhFDtzI5R_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01mNuZrR1GlhFMReUKl_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01I001R51GlhFOj4Bro_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01JBur3E1GlhFPSv000_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01OKxsq81GlhFSp8xPZ_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "张**0",
          skuMap: "颜色分类：促销 518耐磨款 12双+送12双花纱,尺码：L",
          date: "2021-01-09",
          title:
            "胶质手套很好，🚀结实耐磨。戴在手上干起活来依然不影响灵活性，而且非常贴手，保护性很好外观干净，没有胶气，没有疙瘩，带上也挺合适，戴在手上非常舒适，💍非常柔软！很结实，胶皮覆盖均匀，很好用。干点活带着不怕伤手！🌂 ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN011gV60D1YDUkbQEvkY_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01VD1oNI1YDUkbQH8xV_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01YyaSSb1YDUkdDFglL_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01fxC3zT1YDUkezFH1K_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01tM7bOQ1YDUkgN0jAr_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "t**3",
          skuMap: "颜色分类：308经济款  72双（无赠品）,尺码：L",
          date: "2021-01-24",
          title:
            "我公司一直用这家产品，物美价廉，质量好，物流快，服务好，想要的宝贝们赶紧下单哦 ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN019rZcxp1mWdw1rcsIS_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "t**0",
          skuMap: "颜色分类：308经济款  12双（无赠品）,尺码：L",
          date: "2020-12-26",
          title:
            "宝贝收到了。打开一看质量非常好。比市场价又实惠，物流又快，欢迎亲朋好友购买。 ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01hN0jcv1T5BToIAqcd_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN013r7C4j1T5BTt7PSfq_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN014QHHwa1T5BTuvHK2d_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01RDihJI1T5BTyAth8P_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "黄**8",
          skuMap: "颜色分类：518耐磨款 24双,尺码：L",
          date: "2021-01-12",
          title:
            "看着感觉很厚实，还有防滑耐磨功能，感觉很不错，果断入手了，到手看了，手感也是可以的，而且性价比很高。等这次的用完了，下次还来回购。物流很快，已经收到手套了，质量也非常满意。 ",
          pics: [
            "//img.alicdn.com/imgextra/i1/0/O1CN01CNLVkg1aaVtBFWmtE_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01HqVfzD1aaVtG6AoB5_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN017An1Qa1aaVtG6BXvk_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01LlzGlE1aaVtG6BgDt_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN012XAHny1aaVtG6C9Kb_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "李**3",
          skuMap: "颜色分类：308经济款   6双试用,尺码：L",
          date: "2021-02-03",
          title:
            "不买多就买了个体验的6双手套，收到的货就如此差劲，不是发黄就是粘成一起，还没有用就破烂的了。简直就是次品来，味道又大，退货也麻烦。 ",
          pics: [
            "//img.alicdn.com/imgextra/i3/0/O1CN01nml4oJ1nRR36yrbVs_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN012ud2x124QNbW9ONMG_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "忽**8",
          skuMap: "颜色分类：518耐磨款 36双,尺码：L",
          date: "2021-01-16",
          title: "手套还不错，比实体店便宜，舒适耐磨，质量有保障！",
          pics: [
            "//img.alicdn.com/imgextra/i2/0/O1CN01hAfln71PinHAckiO2_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01h5adW61PinHFM9B6J_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01fYAwh51PinHJPy6PV_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN016IoMir1PinHMaa2vH_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01IN5c8A1PinHNZYzk0_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "雨**3",
          skuMap: "颜色分类：308经济款  72双（无赠品）,尺码：L",
          date: "2021-01-15",
          title: "手套还不错。装卸工也说挺好。搬货不会滑 大小也合适 物流也快  ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01yEFT1j1GX2ZTAy2Z1_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01wCxjtd1GX2ZV0hRLF_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01WMEtT71GX2ZWz6eIT_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01kYMOEd1GX2ZbNXpBw_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01jpl7W51GX2ZdLOjeh_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "冷**e",
          skuMap: "颜色分类：308经济款  24双（无赠品）,尺码：L",
          date: "2020-12-12",
          title:
            "非常满意，质量非常好的劳保手套，颜色很酷，料子还不错，滑滑软软的，带上效果也不错，做事带刚刚好，我做事喜欢带手套。这个手套戴在手上不滑。干活利索不受影响。以后就认这家店了，价格比超市便宜不少，实惠 ",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01lz9cY02LZrFkfc6N8_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01xnAM2d2LZrFlBNpUj_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01FzA8Mu2LZrFnAJGJ9_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01nL9Fz72LZrFnMjMtw_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN012Pek122LZrFrIwoQm_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "白**炎",
          skuMap: "颜色分类：518耐磨款 60双,尺码：L",
          date: "2021-01-31",
          title:
            "有一说一，比318的耐磨性好一点，但是味道重的想吐，一股刺鼻的味道 ",
          pics: null,
        },
        {
          goodsId: "599847242748",
          nick: "w**5",
          skuMap: "颜色分类：518耐磨款 60双,尺码：L",
          date: "2021-01-19",
          title: "不错，每个月都采购，很好",
          pics: [
            "//img.alicdn.com/imgextra/i2/0/O1CN01BXQESg26IiCYcxDGQ_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i4/0/O1CN01KmiN8P26IiCct47Ox_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN015qrIg526IiCct6ruF_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "王**伙",
          skuMap: "颜色分类：促销 518耐磨款 12双+送12双花纱,尺码：L",
          date: "2020-12-23",
          title:
            "手套非常好，戴着干活保护手，耐磨。大小也合适。带着不松不紧的刚刚好，做工很好，整个手掌有胶，胶皮也柔软不扎手，非常软和，带在手上非常舒服，反正我够用了，性价比很高，从这家店买的便宜，后悔没多买点。",
          pics: [
            "//img.alicdn.com/imgextra/i1/0/O1CN01brkfUc1NI6xnaFbl9_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01rtXsdx1NI6xsT1sj1_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01CGAHTx1NI6xtn8gs4_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01EEllck1NI6xuP82RM_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i3/0/O1CN01FIMGeV1NI6xuPANz2_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "t**6",
          skuMap: "颜色分类：308经济款  12双（无赠品）,尺码：L",
          date: "2020-12-22",
          title:
            "收到了卖家的宝贝几天了，手套挺好的 抓东西也不滑 ，非常感谢快递速度，手套的舒适度很好，大小标准，弹力够好，质量也是杠杠的，已经购买多次了，值得信赖的又一次购物之旅，卖家服务也是太热情，期待继续惠顾！",
          pics: [
            "//img.alicdn.com/imgextra/i4/0/O1CN01TgYBOp1FteV7WZAfZ_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i2/0/O1CN01WqPnOI1FteV9FHH54_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01X7AOQb1FteV9FIkVw_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN015Djgoq1FteVBEirg1_!!0-rate.jpg",
            "//img.alicdn.com/imgextra/i1/0/O1CN01nzg5Qp1FteVBSwoeD_!!0-rate.jpg",
          ],
        },
        {
          goodsId: "599847242748",
          nick: "j**8",
          skuMap: "颜色分类：促销 518耐磨款 12双+送12双花纱,尺码：L",
          date: "2021-01-03",
          title: "你给发的货不对",
          pics: null,
        },
      ], //买家秀表格数据
      dialogTableVisible: false,
      currentPage: 1, //当前第几页
      total: 500, //表格总条数
      value: "",
      introduce: [
        {
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "1、输入相应的关键词和商品链接,会免费生成一个二维码。买家通过扫描这个二维码,商品会在搜索结果页比较靠前的位置。",
            "2、二维码是通过特定的代码制作出的淘口令,买家扫描二维码后,商品会提高相应的排名。",
            "3、新品没排名可使用此功能,买手可通过扫描生成的二维码,快速找到商品,提高商品关键词流量。",
          ],
        },
        {
          title: "使用场景",
          icon: "&#xe60c;",
          content: [
            "1、新品上架没有排名，没有流量，可通过使用此功能提升新品排名。",
            "2、商家想快速精准地补单，提高搜索权重。",
          ],
        },
        {
          title: "注意",
          icon: "&#xe60b;",
          content: [
            "关键词必须是标题中所包含的词，不可以是关键词延伸出来的词，否则无效果。",
            "新品使用此功能时，选择的关键词尽量长一点，但也不要全标题卡首屏。全标题卡首屏提升的权重不会很高甚至没有变化。",
            "不要用一级词卡屏，新品在一级词下的权重太低，很大概率会没有效果。",
            "新品卡出来之后，若有加购、货比三家等一系列操作的加持，商品所提升的权重会更高。",
            "测试时，尽量用近期没有访问过商品的账号，避免个性化推荐影响结果。",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    // 点击底部分页器时
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMaiJiaXiuFn(this.value);
    },
    // 点击搜索时的方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", "正在获取买家秀相关的数据");
      this.value = value;
      this.CanYouClick = false; //不允许再点击搜索
      this.getMaiJiaXiuFn(value); //买家秀
    },
    // 买家秀
    getMaiJiaXiuFn(value) {
      getMaiJiaXiu({
        itemid: value, // "596446991976""599847242748"
        page: this.currentPage, //请求第几页
      }).then((data) => {
        // console.log(data.data.skuMap);
        this.goodsEntity = [data.data.goodsEntity];
        this.mjxList = data.data.maiJiaXiuList.mjxList;
        this.total = Number(data.data.maiJiaXiuList.totalpage * 20);
      }).finally(() => {
        this.CanYouClick = true; //允许再次搜索
        this.$store.commit("alterJumpFlag", false);
      });
    },
  },
};
</script>
 
<style lang = "less" scoped>
.bannerContent {
  display: flex;
}
/deep/.table-in > .cell {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}
</style>